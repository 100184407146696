import * as yup from 'yup';

export const validateSignUpForm = yup.object({
  name: yup
    .string('Enter your name')
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your name'),
  username: yup
    .string('Enter your username')
    .trim()
    .min(2, 'Please enter a valid username')
    .max(50, 'Please enter a valid username')
    .required('Please specify your username'),
  email: yup
    .string('Enter your email')
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  password: yup
    .string()
    .required('Please specify your password')
    .min(6, 'The password should have at minimum length of 6 characters'),
});
