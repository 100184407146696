import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { NightModeSwitchToggle, Logo, LoginModal } from 'components';
import { Container } from 'common/Container';

export function Header() {
  const theme = useTheme();
  return (
    <Box height="100%" overflow="hidden" width="100%">
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.background.topbar,
        }}
        elevation={0}
      >
        <Container paddingY={{ xs: 1 / 2, sm: 1 }} maxWidth={{ md: '1100px' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Link
              component={RouterLink}
              to="/"
              underline="none"
            >
              <Logo />
            </Link>
            <Box display="flex" alignItems="center">
              <Box marginRight="1rem">
                <NightModeSwitchToggle />
              </Box>
              <LoginModal />
            </Box>
          </Box>
        </Container>
        <Divider />
      </AppBar>
    </Box>
  );
}
