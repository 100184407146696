import { Header, Footer, SignUpForm } from 'components';

export function Home() {
  return (
    <>
      <Header />
      <SignUpForm />
      <Footer />
    </>
  );
}
