import { useSelector } from 'react-redux';
import DarkLogo from 'assets/svg/logo_dark.svg';
import LightLogo from 'assets/svg/logo_light.svg';

export function Logo() {
  const { darkMode } = useSelector((state) => state);

  return (
    <img src={darkMode.active ? DarkLogo : LightLogo} alt="Logo" />
  );
}
