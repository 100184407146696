import { Header, Footer, NotFoundMsg } from 'components';

export function NotFound() {
  return (
    <>
      <Header />
      <NotFoundMsg />
      <Footer />
    </>
  );
}
